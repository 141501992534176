import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="finance-calculator"
export default class extends Controller {
  static targets = [ "time", "totalAmount", "initialPayment", "monthlyPayment", "total", "fees", "option",
    "textInitialPayment", "textMonths", "textMonthlyPayment", "textInitialAmount" ]
  variables = [0.093387, 0.049044, 0.034252, 0.026889, 0.022513, 0.019641, 0.017622, 0.016150, 0.015068, 0.014271]

  connect() {
    this.calcular();
  }

  calcular() {
    const activeRadio = this.optionTargets.find(radio => radio.checked);
    const coefficient = parseFloat(activeRadio.getAttribute("data-coefficient"));
    const months = parseInt(activeRadio.value, 10);
    let totalAmount = parseInt(this.totalAmountTarget.getAttribute("data-total-amount"));
    let initialPayment = parseInt(this.initialPaymentTarget.value) || 0;

    let finalAmount = totalAmount - initialPayment
    let monthlyPayment = finalAmount * coefficient;
    let total = monthlyPayment * months;
    let fees = total - finalAmount;

    this.monthlyPaymentTarget.innerText = `${monthlyPayment.toFixed(0)}€`;

    if (document.getElementById('textMonthlyPayment')) {
      document.getElementById('textMonthlyPayment').innerText = `${monthlyPayment.toFixed(0)}€`;
    }
    if (document.getElementById('textInitialPayment')) {
      document.getElementById('textInitialPayment').innerText = `${initialPayment.toFixed(0)}€`;
    }
    if (document.getElementById('textInitialAmount')) {
      document.getElementById('textInitialAmount').innerText = `${totalAmount.toFixed(0)}€`;
    }
    if (document.getElementById('textMonths')) {
      document.getElementById('textMonths').innerText = `${months}`;
    }
    // this.totalTarget.innerText = `${total.toFixed(0)}€`;
    this.totalAmountTarget.innerText = `${finalAmount.toFixed(0)}€`;
    // this.feesTarget.innerText = `${fees.toFixed(0)}€`;
  }
}