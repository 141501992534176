import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

// Connects to data-controller="filter-fuel"
export default class extends Controller {
  static targets = [ "fuelCheckbox" ]

  connect() {
  }

  handleCheckboxChange() {
    const selectedFuels = [];

    this.fuelCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        selectedFuels.push(checkbox.value);
      }
    });

    this.refreshUrl(selectedFuels)
  }

  refreshUrl(selectedFuels) {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;


    if (selectedFuels.length) {
      urlParams.set('fuels', selectedFuels);
    } else {
      urlParams.delete('fuels');
    }
    url.search = urlParams.toString();

    const src = url.pathname + '.turbo_stream' + url.search
    const element = document.getElementById('cars_list_tb');
    const siblings = Array.from(element.parentNode.children).filter(child => child !== element);

    siblings.forEach(sibling => {
      sibling.parentNode.removeChild(sibling);
    });
    history.pushState(null, '', url.pathname + url.search);

    element.src = src
    element.reload();
  }
}
