// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";
import createCarouselSlider, { createModalCarouselSlider } from './carousel-slider.esm';
import './select2-filters.js';


/**
 * Carousel Slider element
 */
const sliderEl = document.querySelector('.carousel-slider');
const modalSliderEl = document.querySelector('.modal-carousel-slider');
/**
 * Init Carousel Slider
 *
 * argument: pass .carousel-slider element
 */
createCarouselSlider(sliderEl);
createModalCarouselSlider(modalSliderEl);

//Popover calculator terms
var options = {
    html: true,
    content: document.querySelector('[data-name="popover-content"]')
}

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl, options)
        })
//button close popover     
var popoverCloseButton = document.getElementById('popoverCloseButton');
popoverCloseButton.addEventListener('click', function() {
    popoverList.map(popover => {
        popover.hide();
    });
});