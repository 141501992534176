import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="car-count"
export default class extends Controller {
  static targets = ["list"];

  connect() {
    this.updateCount();

    this.observer = new MutationObserver(() => {
      this.updateCount();
    });

    this.observer.observe(this.listTarget, { childList: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  updateCount() {
    const numberOfDivs = this.listTarget.querySelectorAll('div.col-12').length;

    // Directly update the count in all the .car-count elements
    const carCountSpans = document.querySelectorAll('.car-count');
    carCountSpans.forEach(span => {
      span.textContent = numberOfDivs;
    });
  }
}