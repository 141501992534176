import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

// Connects to data-controller="filter-order"
export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    this.handleOrderChange()
  }

  handleOrderChange() {
    const that = this;
    $(this.selectTarget).on('change', function (event) {
      let selectedValue = $(this).val();
      const [field, direction] = selectedValue.split(':')

      that.refreshList(field, direction)
    });
  }

  refreshList(field, direction) {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;

    if (field && direction) {
      urlParams.set('order_by', [field, direction].join(':'));
    } else {
        urlParams.delete('order_by');
    }

    url.search = urlParams.toString();

    Turbo.visit(url.toString());
  }
}
