// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';


export default function createCarouselSlider(el) {
    const swiperEl = el.querySelector('.swiper');
    const swiper = new Swiper(swiperEl, {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],
        
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // Navigation arrows
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        },

    });
}

export function createModalCarouselSlider(el) {
    const swiperEl = el.querySelector('.normal-swiper');
    const thumbsSwiperEl = el.querySelector('.thumbs-swiper');

    const thumbsSwiper = new Swiper(thumbsSwiperEl, {
        spaceBetween: 10,
        slidesPerView: 10,
        freeMode: true,
        watchSlidesProgress: true,
      });

    const swiper = new Swiper(swiperEl, {
        // configure Swiper to use modules
        modules: [Navigation, Thumbs],

        // Optional parameters
        direction: 'horizontal',
        loop: false,

        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: thumbsSwiper,
        },

    });
}

