import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="car-card"
export default class extends Controller {
  static targets = [ "card" ]

  connect() {
    this.cardTarget.addEventListener('click', this.navigateTo.bind(this))
  }

  navigateTo() {
    window.location.href = this.cardTarget.dataset.url;
  }
}
