import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

// Connects to data-controller="filter-brand"
export default class extends Controller {
  static targets = [ "brand", "model" ]

  connect() {
    this.load()
  }

  load() {
    let brand, model;
    let that = this;
    if (this.hasBrandTarget) {
      this.updateModelSelect('')

      $(this.brandTarget).on('change', function (event) {
        brand = $(this).val().filter(elm => elm);
        model = undefined;
        that.updateModelSelect(brand)
        that.refreshList(brand, model);
      });
    }
    if (this.hasModelTarget) {
      $(this.modelTarget).on('change', function (event) {
        model = $(this).val().filter(elm => elm);
        that.refreshList(brand, model);
      });
    }
  }

  updateModelSelect(brand) {
    fetch(`coches-segunda-mano/services/filters/models?name=${brand}`)
      .then(response => response.json())
      .then(data => {
        const currentUrlParams = new URLSearchParams(window.location.search);
        let selectedModels = (currentUrlParams.get('models') || '').split(',');
        const { models } = data
        this.modelTarget.innerHTML = ''
        models.forEach(element => {
          let option = document.createElement('option')
          option.text = element[1]
          option.value = element[0]
          option.selected = selectedModels.includes(element[0]);
          this.modelTarget.add(option)
        })
        this.modelTarget.disabled = false
      })
  }

  refreshList(brand, model) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    let current_path = window.location.pathname.split('/')[1] + '/' + window.location.pathname.split('/')[2]
    let nuevaUrl = window.location.protocol + "//" + window.location.host + '/' + current_path;
    let url = new URL(nuevaUrl);
    let urlParams = url.searchParams;

    let oldBrands = currentUrlParams.get('brands');


    console.log('brand', brand);
    console.log('oldBrands', oldBrands);
    if (brand && brand.length) {
      urlParams.set('brands', brand.join(','));
    } else {
        urlParams.delete('brands');
    }

    if (model && model.length) {
      urlParams.set('models', model.join(','));
    } else {
      urlParams.delete('models');
    }

    url.search = urlParams.toString();

    const src = url.pathname + '.turbo_stream' + url.search
    const element = document.getElementById('cars_list_tb');
    const siblings = Array.from(element.parentNode.children).filter(child => child !== element);

    siblings.forEach(sibling => {
      sibling.parentNode.removeChild(sibling);
    });
    history.pushState(null, '', url.pathname + url.search);

    element.src = src
    element.reload();
  }
}
