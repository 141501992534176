import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

// Connects to data-controller="filter-cv"
export default class extends Controller {
  static targets = [ "type", "min", "max" ]

  connect() {
    this.load()
  }

  load() {
    let min, max;
    let that = this;
    if (this.hasMinTarget && this.hasMaxTarget) {
      $(this.minTarget).on('select2:select', function (event) {
        min = event.target.value;
        that.refreshUrl(min, 'min')
      });
      $(this.maxTarget).on('select2:select', function (event) {
        max = event.target.value;
        that.refreshUrl(max, 'max')
      });
    }
  }

  refreshUrl(value, label) {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;
    if (label === 'min') {
      if (!!value) {
        urlParams.set('cv_min', value);
      } else {
        urlParams.delete('cv_min');
      }
    }

    if (label === 'max') {
      if (!!value) {
        urlParams.set('cv_max', value);
      } else {
        urlParams.delete('cv_max');
      }
    }

    url.search = urlParams.toString();
    console.log(url.search);

    const src = url.pathname + '.turbo_stream' + url.search
    const element = document.getElementById('cars_list_tb');
    const siblings = Array.from(element.parentNode.children).filter(child => child !== element);

    siblings.forEach(sibling => {
      sibling.parentNode.removeChild(sibling);
    });
    history.pushState(null, '', url.pathname + url.search);

    element.src = src
    element.reload();
  }
}

