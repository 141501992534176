import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="financed-price"
export default class extends Controller {
  static targets = ["value", "price"];

  connect() {
    const initialPrice = this.valueTarget.dataset.price
    this.valueTarget.innerHTML = "-";
    this.fetchPrice(initialPrice);
  }

  fetchPrice(initialPrice) {
    let EURFormat = new Intl.NumberFormat('es-ES');

    this.valueTarget.innerHTML = EURFormat.format(this.calculate(initialPrice)) + "€";

  }

  calculate(initialPrice) {
    let coefficient = 0.014271;
    let totalAmount = initialPrice;

    let monthlyPayment = (totalAmount - 0) * coefficient;
    return monthlyPayment.toFixed(0);
  }
}
