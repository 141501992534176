import jQuery from 'jquery';
import $ from 'jquery';
//import 'select2';
import select2 from 'select2';
select2();
//import '../assets/stylesheets/select2-filters.scss';



document.addEventListener('turbo:load', function () {

  //MULTIPLE CHECKBOXES SELECT2 PLUGIN
  /**
   * jQuery Select2 Multi checkboxes
   * - allow to select multi values via normal dropdown control
   *
   * author      : wasikuss
   * repo        : https://github.com/wasikuss/select2-multi-checkboxes
   * inspired by : https://github.com/select2/select2/issues/411
   * License     : MIT
   */
  (function($) {
      var S2MultiCheckboxes = function(options, element) {
        var self = this;
        self.options = options;
        self.$element = $(element);
        var values = self.$element.val();
        self.$element.removeAttr('multiple');
        self.select2 = self.$element.select2({
          allowClear: true,
          minimumResultsForSearch: options.minimumResultsForSearch,
          placeholder: options.placeholder,
          closeOnSelect: false,
          templateSelection: function() {
            return self.options.templateSelection(self.$element.val() || [], $('option', self.$element).length);
          },
          templateResult: function(result) {
            if (result.loading !== undefined)
              return result.text;
            return $('<div>').text(result.text).addClass(self.options.wrapClass);
          },
          matcher: function(params, data) {
            var original_matcher = $.fn.select2.defaults.defaults.matcher;
            var result = original_matcher(params, data);
            if (result && self.options.searchMatchOptGroups && data.children && result.children && data.children.length != result.children.length) {
              result.children = data.children;
            }
            return result;
          }
        }).data('select2');
        self.select2.$results.off("mouseup").on("mouseup", ".select2-results__option[aria-selected]", (function(self) {
          return function(evt) {
            var $this = $(this);
        
        const Utils = $.fn.select2.amd.require('select2/utils')
            var data = Utils.GetData(this, 'data');
    
            if ($this.attr('aria-selected') === 'true') {
              self.trigger('unselect', {
                originalEvent: evt,
                data: data
              });
              return;
            }
    
            self.trigger('select', {
              originalEvent: evt,
              data: data
            });
          }
        })(self.select2));
        self.$element.attr('multiple', 'multiple').val(values).trigger('change.select2');
      }
    
      $.fn.extend({
        select2MultiCheckboxes: function() {
          var options = $.extend({
            placeholder: 'Choose elements',
            templateSelection: function(selected, total) {
              return selected.length + ' > ' + total + ' total';
            },
            wrapClass: 'wrap',
            minimumResultsForSearch: -1,
            searchMatchOptGroups: true
          }, arguments[0]);
    
          this.each(function() {
            new S2MultiCheckboxes(options, this);
          });
        }
      });
    })(jQuery);
  //END MULTIPLE CHECKBOXES SELECT2 PLUGIN

  //BRANDS
 $('.select2-multiple2-marcas').select2MultiCheckboxes({
      templateSelection: function (selected, total) {
          return 'Marcas: ' + selected.length;
      },
      width: "auto",
     selectOnClose: false,
      dropdownAutoWidth: true
  });
  $(".select2-multiple2-marcas").change(function () {

      var $option = $(this).val();
      //console.log('select2 - marcas - ' + $option);
      if ($option.includes('all')) {
          $(".select2-multiple2-marcas > option").prop("selected", "selected");
          $(".select2-multiple2-marcas > option[value='all']").removeAttr("selected");
          $(".select2-multiple2-marcas > option[value='all']").prop("selected", "");
          $(".select2-multiple2-marcas").trigger("change");

          //$(".select2-multiple2-marcas > option[value='all']").prop("selected", "selected");

          //console.log('select2 - option_all');
      }
  });
  //MODELS
  $('.select2-multiple2-models').select2MultiCheckboxes({
      templateSelection: function (selected, total) {
          return 'Modelos: ' + selected.length;
      },
      width: "auto",
      dropdownAutoWidth: true
  });
  $(".select2-multiple2-models").change(function () {
      var $option = $(this).val();
      if ($option.includes('all')) {
          $(".select2-multiple2-models > option").prop("selected", "selected");
          $(".select2-multiple2-models > option[value='all']").removeAttr("selected");
          $(".select2-multiple2-models > option[value='all']").prop("selected", "");
          $(".select2-multiple2-models").trigger("change");
      }
  });
  
  //ORDERBY
  $('.select2-orderby').select2({
      minimumResultsForSearch: -1
  });

  //FROM PRICE
  $('.select2-from-price').select2({
      minimumResultsForSearch: -1
  });
  //TO PRICE
  $('.select2-to-price').select2({
      minimumResultsForSearch: -1
  });
  //FROM REGISTRATION
  $('.select2-from-registration').select2({
      minimumResultsForSearch: -1
  });
  //TO REGISTRATION
  $('.select2-to-registration').select2({
      minimumResultsForSearch: -1
  });
  //FROM KMS
  $('.select2-from-kms').select2({
      minimumResultsForSearch: -1
  });
  //TO KMS
  $('.select2-to-kms').select2({
      minimumResultsForSearch: -1
  });
  //FROM CV
  $('.select2-from-cv').select2({
      minimumResultsForSearch: -1
  });
  //TO CV
  $('.select2-to-cv').select2({
      minimumResultsForSearch: -1
  });

  //LOCATIONS
  $('.select2-multiple2-locations').select2MultiCheckboxes({
      templateSelection: function (selected, total) {
          return 'Localizaciones: ' + selected.length;
      },
      width: "auto",
      dropdownAutoWidth: true
  });
  $(".select2-multiple2-locations").change(function () {
      var $option = $(this).val();
      if ($option.includes('all')) {
          $(".select2-multiple2-locations > option").prop("selected", "selected");
          $(".select2-multiple2-locations > option[value='all']").removeAttr("selected");
          $(".select2-multiple2-locations > option[value='all']").prop("selected", "");
          $(".select2-multiple2-locations").trigger("change");
      }
  });

});



