import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

// Connects to data-controller="filter-price"
export default class extends Controller {
  static targets = [ "type", "min", "max" ]

  connect() {
    this.load()
  }

  load() {
    let min, max;
    let that = this;
    if (this.hasMinTarget && this.hasMaxTarget) {
      $(this.minTarget).on('select2:select', function (event) {
        min = event.target.value;
        that.refreshUrl(min, 'min')
      });
      $(this.maxTarget).on('select2:select', function (event) {
        max = event.target.value;
        that.refreshUrl(max, 'max')
      });
    }
  }

  refreshUrl(value, label) {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;
    console.log(!!value);

    if (!!value && value < 1000) {
      urlParams.set('payment_type', 'monthly');
    } else if (!!value && value > 1001) {
      urlParams.set('payment_type', 'total');
    } else {
      urlParams.delete('payment_type');
    }

    if (label === 'min') {
      if (!!value) {
        urlParams.set('price_min', value);
      } else {
        urlParams.delete('price_min');
      }
    }

    if (label === 'max') {
      if (!!value) {
        urlParams.set('price_max', value);
      } else {
        urlParams.delete('price_max');
      }
    }

    url.search = urlParams.toString();

    Turbo.visit(url.toString());
  }

  changeToMonthlyQuota() {
    if (this.hasMinTarget && this.hasMaxTarget) {
      this.updateSelectOptions(this.minTarget, 200, 1000, 100);
      this.updateSelectOptions(this.maxTarget, 200, 1000, 100);
    }
  }

  updateSelectOptions(selectElement, start, end, step) {
    let options = [`<option value="all">Todos</option>`];
    for (let i = start; i <= end; i += step) {
      options.push(`<option value="${i}">${i} €</option>`);
    }

    $(selectElement).empty().append(options.join('')).trigger('change');
  }

  changeToTotalPrice() {
    if (this.hasMinTarget && this.hasMaxTarget) {
      this.updateSelectOptions(this.minTarget, 5000, 130000, 5000);
      this.updateSelectOptions(this.maxTarget, 5000, 130000, 5000);
    }
  }
}
